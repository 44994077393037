import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {useIntl} from "gatsby-plugin-intl"

const Cookies = () => {
  const intl = useIntl();

  return (
    <Layout>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          //SHOULD BE USED ONLY ON COOKIE POLICY. TRIGGER FUNCTION BELOW TO REMOVE DUPLICATE CATEGORIES
          function clearDup() {
          var sec = document.getElementById("ot-sdk-cookie-policy")
          var tally = [];
          for (var i = sec.length - 1; i >= 0; i--) {
          if (tally[sec[i].firstChild.innerText] === undefined) {
          tally[sec[i].firstChild.innerText] = 1;
        } else {
          //console.log(i,sec[i].firstChild.innerText);
          sec[i].remove();
          //return true;
        }
        }
          //return false;
        } 
        `,
        }}
      />
      <SEO title="COOKIES" />


      <div className="white-container" id="legals">

        <h1 className="text-center dark-grey text-uppercase mb-5">{intl.formatMessage({ id: "cookies.title" })}</h1>

        <div dangerouslySetInnerHTML={{__html: `<button id="ot-sdk-btn" class="ot-sdk-show-settings button red md-4"
        onclick="Optanon.ToggleInfoDisplay()">Cookie Settings</button>`
        }}/>

        <h2>{intl.formatMessage({ id: "cookies.title2" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text2" })}} />

        <h2>{intl.formatMessage({ id: "cookies.title3" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text3" })}} />

        { (intl.locale === 'fr-BE' || intl.locale === 'fr-LU') &&
          <h2>{intl.formatMessage({id: "cookies.title4"})}</h2>
        }
        { (intl.locale === 'fr-BE' || intl.locale === 'fr-LU') &&
          <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "cookies.text4"})}} />
        }

        <h2>{intl.formatMessage({ id: "cookies.title5" })}</h2>
        <h3>{intl.formatMessage({ id: "cookies.subtitle1" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text5" })}} />
        <h3>{intl.formatMessage({ id: "cookies.subtitle2" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text6" })}} />
        <h3>{intl.formatMessage({ id: "cookies.subtitle3" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text7" })}} />

        <h2>{intl.formatMessage({ id: "cookies.title6" })}</h2>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text8" })}} />

        <h2>{intl.formatMessage({ id: "cookies.title7" })}</h2>
        <h3>{intl.formatMessage({ id: "cookies.subtitle4" })}</h3>
        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "cookies.text9" })}} />

      </div>

    </Layout>
  )
}

export default Cookies

